import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import ErrorBoundary from './components/ErrorBoundary';
import Loading from './components/common/Loading';
import Navbar from './components/layout/Navbar/Navbar';
import Footer from './components/layout/Footer/Footer';
import BuildFastBot from './components/features/BuildFastBot/BuildFastBot';
import './App.css';

// Lazy load components
const Home = React.lazy(() => import('./components/home/Home'));
const Bootcamp = React.lazy(() => import('./components/pages/Bootcamp/Bootcamp'));
const Events = React.lazy(() => import('./components/pages/Events/Events'));
const Resources = React.lazy(() => import('./components/pages/Resources/Resources'));
const AppShowcase = React.lazy(() => import('./components/home/AppShowcase/AppShowcase'));
const SignIn = React.lazy(() => import('./components/features/SignIn/SignIn'));
const LaunchPad = React.lazy(() => import('./components/home/LaunchPad/LaunchPad'));

function App() {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <Router>
          <div className="App">
            <Navbar />
            <Suspense fallback={<Loading />}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/bootcamp" element={<Bootcamp />} />
                <Route path="/events" element={<Events />} />
                <Route path="/resources/*" element={<Resources />} />
                <Route path="/app-showcase" element={<AppShowcase />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/launchpad" element={<LaunchPad />} />
              </Routes>
            </Suspense>
            <Footer />
            <BuildFastBot />
          </div>
        </Router>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;
