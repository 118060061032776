import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { images } from '../../../assets/images';
import './Navbar.css';

const Navbar = () => {
  const { user, signInWithGoogle, signOut } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`navbar ${scrolled ? 'scrolled' : ''}`}>
      <div className="navbar-container">
        <Link to="/" className="navbar-logo">
          <img src={images.logo} alt="AgentVersity" className="logo" />
        </Link>

        <div className="nav-center">
          <div className={`navbar-menu ${isOpen ? 'active' : ''}`}>
            <div className="nav-links">
              <Link to="/bootcamp" className="nav-item">
                <span>GenAI Bootcamp</span>
              </Link>
              <Link to="/resources" className="nav-item">
                <span>Resources</span>
              </Link>
              <Link to="/app-showcase" className="nav-item">
                <span>App Showcase</span>
              </Link>
              <Link to="/events" className="nav-item">
                <span>Events</span>
              </Link>
              <div 
                className="nav-item more-dropdown"
                onMouseEnter={() => setShowMore(true)}
                onMouseLeave={() => setShowMore(false)}
              >
                <span>More <i className="arrow-down">▼</i></span>
                {showMore && (
                  <div className="dropdown-content">
                    <Link to="/about">About Us</Link>
                    <Link to="/contact">Contact</Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="nav-actions">
          {user ? (
            <div className="user-menu">
              <img 
                src={user.user_metadata.avatar_url} 
                alt="Profile" 
                className="profile-image"
              />
              <button onClick={signOut} className="signin-button">
                Sign Out
              </button>
            </div>
          ) : (
            <button onClick={signInWithGoogle} className="signin-button">
              Sign in with Google
            </button>
          )}
          <div className="social-icons">
            <a href="https://www.linkedin.com/company/firstimpress-ai/posts/?feedView=all" target="_blank" rel="noopener noreferrer">
              <img src={images.linkedin} alt="LinkedIn" />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <img src={images.twitter} alt="Twitter" />
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar; 